import React from "react"
import { Layout } from "../components/common"
import { Helmet } from "react-helmet"

import bandWave from "../components/assets/images/bg/banner4-wave1.svg"
import waterMark from "../components/assets/images/bg/watermark2-bg.png"
import waterMark2 from "../components/assets/images/bg/watermark2-bg.png"
const Events = () => (
  <Layout>
    <Helmet>
      <title>Events</title>
    </Helmet>
    {/* <Employee /> */}
    <>
      <div className="banner-section4" style={{ minHeight: "60vh" }}>
        <img src={bandWave} className="banner4-wave1 img-fluid" alt="Wave" />
        <img
          src={waterMark2}
          className="banner4-wave2 img-fluid"
          alt="Wave 1"
        />

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div
                className="section-title3 primary4 text-cener"
                style={{ marginBottom: "12px" }}
              >
                <span>-Events-</span>
                <h3>Events and Activites</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="service-section4 pt-120 pb-120 position-relative overflow-hidden"
        style={{ paddingTop: "25px", paddingBottom: "25px" }}
      >
        <img
          src={waterMark}
          className="watermark1-bg"
          alt="watermark background"
        />
        <div className="container">
          <div
            class="section-title3 primary4 text-start"
            style={{ marginBottom: "5px" }}
          >
            <h1>
              Join us for a farm walk at Spa Cottage on Sunday 23rd March 2024
            </h1>
          </div>
          <div className="content">
            <p className="para">
              The event starts at 1pm at the Dock, Carrick-on-Shannon, Co.Leitrim
              followed by group travel to the marquee on the farm at Spa Cottage
              Organic Farm, Dublin Rd, carrick-on-Shannon, Co.Leitrim approx one
              hour later. Please be sure to confirm your place using the booking
              form{" "}
              <a
                href=" https://forms.leitrimcoco.ie/form-72241/farm-walks-booking"
                target="_blank"
                rel="noopener noreferrer"
              >
                here.
              </a>
            </p>

            <p className="para">
              Transport provided to and from Spa Cottage Farm on the day.
              Full itinerary and booking form can be found by visiting{" "}
              <a
                href=" https://forms.leitrimcoco.ie/form-72241/farm-walks-booking"
                target="_blank"
                rel="noopener noreferrer"
              >
                here.
              </a>
            </p>

            <p className="para">
              This event is part of the Creative Ireland Shared Island
              Programme. Joint participation by Leitrim County Council and
              Fermanagh County Council.The event is a collaboration between
              artists and farmers.
            </p>
          </div>
        </div>
      </div>
    </>
  </Layout>
)

export default Events
